import React from 'react';
import { withFirebase } from 'components/Firebase';

export const AuthContext = React.createContext<any | null>(null);

export const AuthProvider = withFirebase(({ children, ...props }) => {
	const [ authUser, setAuthUser ] = React.useState()
	const [ claims, setClaims ] = React.useState();
	React.useEffect(() => {
		props.firebase.auth.onAuthStateChanged(setAuthUser);
		if (authUser) {
			props.firebase.getCurrentUserClaims()
				.then((result) => {
					const { customerRef, role } = result;
					if (customerRef && role) {
						setClaims(result)
					} else {
						console.log('Authenticated user has no claims')
						props.firebase.doSignOut();
					}
				}).catch((err) => {
					console.log('ERROR', err)
				});
		}

	}, [ setAuthUser, authUser, setClaims ]);



	return (
		<AuthContext.Provider value={{ authUser, claims }}>
			{children}
		</AuthContext.Provider>
	)
});