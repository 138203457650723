import React from 'react';
import Add16 from '@carbon/icons-react/es/add/16';
import Edit16 from '@carbon/icons-react/es/edit/16';
import DataTable from 'carbon-components-react/lib/components/DataTable';
import Pagination from '@carbon/ibm-security/lib/components/Pagination';
import DataTableSkeleton from 'carbon-components-react/lib/components/DataTableSkeleton';
import Button from 'carbon-components-react/lib/components/Button';
import { ICustomerLocation, ETableHeaderMap } from '../Customers/interface';
import { getItemRangeText, getPageRangeText, ITableHeaderItem, IRow, ICell } from 'utils/table';
import { PageSizes } from 'utils/table';
import { AuthContext } from 'components/Auth';

import { EMode } from './interface';
import CustomerContext from './CustomerContext';
import { ERoles } from 'modules/Manage/ManageInterfaces';

const {
	Table,
	TableContainer,
	TableHead,
	TableHeader,
	TableBody,
	TableCell,
	TableRow,
	TableToolbar,
	TableToolbarContent,
	TableToolbarSearch
} = DataTable;
const headers: ITableHeaderItem[] = [
	{
		header: ETableHeaderMap.CONTACT,
		key: 'contactName'
	},
	{
		header: ETableHeaderMap.REFERENCE,
		key: 'reference'
	},
	{
		header: ETableHeaderMap.PHONE,
		key: 'contactPhoneNumber'
	},
	{
		header: ETableHeaderMap.EMAIL,
		key: 'contactEmail'
	},
	{
		header: '',
		key: ETableHeaderMap.EDIT
	}
];

const defaultCustomerLocation = {
	address: {
		addressLine1: '',
		addressLine2: '',
		city: '',
		country: '',
		postcode: '',
		state: ''
	},
	companyName: '',
	contactEmail: '',
	contactName: '',
	contactPhoneNumber: '',
	id: '',
	reference: '',
	serviceContactEmail: '',
	serviceContactName: '',
	serviceContactPhone: ''
}


const CustomersTable: React.FunctionComponent = () => {
	const AuthCTX = React.useContext(AuthContext);
	const Context = React.useContext(CustomerContext);

	const [ rows, setRows ] = React.useState(Context.state.customers)
	const [ pagination, setPagination ] = React.useState({ pageSize: PageSizes.pageSizes[ 0 ], page: 1 })


	React.useEffect(() => {
		if (Context.state.customers) {
			setRows(Context.state.customers)
		}
	}, [ Context ])

	const addEditCustomer = React.useCallback((mode: string, id?: string) => {

		let customer = {} as ICustomerLocation | undefined;
		if (id) {
			customer = rows.find((customerObj: ICustomerLocation) => {
				return customerObj.id === id;
			});
			Context.handleCustomerId(id);
		} else {
			customer = defaultCustomerLocation
		}

		if (customer) {
			Context.dispatch({
				type: 'customer',
				value: customer
			});
			Context.dispatch({
				type: 'addEditViewEnabled',
				value: {
					addEditViewEnabled: true,
					mode
				}
			});
		}

	}, [ Context, rows ]);


	// const filterRows = React.useCallback((rows: ICustomer[]) => {
	// 	const startIndex =
	// 		(pagination.page - 1) * pagination.pageSize;
	// 	const endIndex =
	// 		pagination.page * pagination.pageSize;
	// 	return rows.filter((row: ICustomer, index: number) => {
	// 		if (index >= startIndex && index < endIndex) return true;
	// 		return false;
	// 	});
	// }, [pagination.page, rows]);

	const handlePageChange = React.useCallback((pageChange: { page: number; pageSize: number }): void => {

		setPagination({ ...pageChange })

	}, [ setPagination ]);

	return (
		<React.Fragment>
			<div className={'sc--table-wrapper bx--data-table--zebra'}>
				<DataTable
					rows={rows}
					headers={headers}
					render={({ rows, headers, getHeaderProps }) => (
						<TableContainer>
							<TableToolbar>
								<TableToolbarContent>
									<TableToolbarSearch
										className='sc--search-input'
										persistent={true}
										onChange={Context.handleCustomerLocationSearch}
										placeHolderText={'Filter by name'}
									/>
									{AuthCTX.claims.role === ERoles.MANAGER && (
										<Button
											kind={'primary'}
											renderIcon={Add16}
											iconDescription={'Add service location'}
											onClick={() => addEditCustomer(EMode.ADD)}
										>
											Add service location
										</Button>
									)}
								</TableToolbarContent>
							</TableToolbar>
							{Context.isLoading ? (
								<DataTableSkeleton />
							) : (
								<React.Fragment>
									<Table>
										<TableHead>
											<TableRow>
												{headers.map((header: ITableHeaderItem) => (
													// eslint-disable-next-line react/jsx-key
													<TableHeader
														{...getHeaderProps({ header })}>
														{header.header}
													</TableHeader>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{rows.map((row: IRow) => (
												<TableRow key={row.id} className={'sc--table-row'}>
													{row.cells.map((cell: ICell) => {
														if (cell.info.header === 'Edit') {
															return (
																<TableCell key={cell.id}>
																	<Button
																		className={'sc--edit-table-icon'}
																		kind={'ghost'}
																		renderIcon={Edit16}
																		iconDescription={'Edit customer'}
																		onClick={() => {
																			addEditCustomer(EMode.EDIT, row.id)
																		}}
																	/>
																</TableCell>
															);
														} else {
															return (
																<TableCell key={cell.id}>
																	{cell.value}
																</TableCell>
															);
														}
													})}
												</TableRow>
											))}
										</TableBody>
									</Table>
									<Pagination
										backwardText='Previous page'
										disabled={false}
										forwardText='Next page'
										isLastPage={false}
										itemRangeText={getItemRangeText}
										itemsPerPageText='Items per page:'
										onChange={handlePageChange}
										page={pagination.page}
										pageInputDisabled
										pageNumberText='Page Number'
										pageRangeText={getPageRangeText}
										pageSize={pagination.pageSize}
										pageSizes={PageSizes.pageSizes}
										totalItems={rows.length}
									/>
								</React.Fragment>
							)}
						</TableContainer>
					)}
				/>
			</div>
		</React.Fragment>
	);
}

export {
	CustomersTable
}
