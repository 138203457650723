/* eslint-disable no-undef */

interface ICustomerContextProps {
	createNewCustomerLocation: () => void;
	dispatch: ({ type }: { type: string; value: any; step?: string; isNested?: boolean }) => void;
	handleCustomerId: (customerId: string) => void;
	handleCustomerLocationSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
	isLoading: boolean;
	state: ICustomerInitialState;
	updateCustomerLocation: () => void;
}

interface ICustomerInitialState {
	customers: ICustomerLocation[];
	customer: ICustomerLocation;
	mode: string;
	tableDataLoaded: boolean;
	addEditViewEnabled: boolean;
	inputValidation: { [ key: string ]: any };
}

interface ICustomer {
	address: ICustomerAddress;
	billing: string;
	companyName: string;
	contactEmail: string;
	contactName: string;
	contactPhoneNumber: string;
	id: string;
	logo: string;
	paymentEmail: string;
	reference: string | undefined;
	serviceContactEmail: string;
	serviceContactName: string;
	serviceContactPhone: string;
}

interface ICustomerAddress {
	addressLine1: string;
	addressLine2: string;
	city: string;
	country: string;
	postcode: string;
	state: string;
}

interface ICustomerLocation {
	address: ICustomerAddress;
	companyName: string;
	contactEmail: string;
	contactName: string;
	contactPhoneNumber: string;
	customer?: ICustomerReference;
	geometry?: {
		location: ICoords;
	};
	id: string;
	reference: string;
	serviceContactEmail: string;
	serviceContactName: string;
	serviceContactPhone: string;
}

// interface ICustomerLocation {
// 	address: ICustomerAddress;
// 	customer: {
// 		id: string;
// 		logoUrl?: string;
// 		name: string;
// 	};
// 	geometry: {
// 		location: ICoords;
// 	};
// 	serviceContactEmail: string;
// 	serviceContactName: string;
// 	docRef?: string;
// }

// interface ICustomerLocations {
// 	address: ICustomerAddress;
// 	companyName: string;
// 	contactEmail: string;
// 	contactName: string;
// 	contactPhoneNumber: string;
// 	serviceContactEmail: string;
// 	serviceContactName: string;
// }

interface ICoords {
	lat?: number | string;
	lng?: number | string;
}

interface IName {
	name: string;
}

interface IGoogleMarker {
	name: string;
	location: ICoords;
	reference: string;
}

// interface ISelectedCustomer extends ICustomerLocationDetails, IName {}

interface ICustomerReference {
	id: string;
	logoUrl: string;
	name: string;
	paymentEmail: string;
}

interface ICustomerCtx {
	details: ICustomer;
}

enum EMode {
	EDIT = 'Edit',
	ADD = 'Add'
}

enum ETableHeaderMap {
	CLOSED_REQUESTS = 'Close requests',
	CONTACT = 'Contact',
	CUSTOMER = 'Customer',
	INVOICED_TOTALS = 'Invoiced totals',
	EDIT = 'Edit',
	EMAIL = 'Email',
	MAGNIFY = 'Magnify',
	NAME = 'Name',
	OPEN_REQUESTS = 'Open requests',
	PHONE = 'Phone',
	ROLE = 'Role',
	REFERENCE = 'Reference',
	LOCATION = 'Location',
	STORE_REFERENCE = 'Store #',
	EMPTY = ''
}

export {
	ICustomerInitialState,
	ICustomerContextProps,
	EMode,
	ETableHeaderMap,
	ICustomerAddress,
	ICustomerReference,
	// ISelectedCustomer,
	ICustomerLocation,
	IGoogleMarker,
	ICoords,
	ICustomer,
	ICustomerCtx as ICustomerBase
	// ICustomerLocations
	// EPostcodes
};
