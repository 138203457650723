/* eslint-disable @typescript-eslint/no-non-null-assertion */
import TextInput from '@carbon/ibm-security/lib/components/TextInput';
import React from 'react';
import { CustomTextInput } from 'components/CustomTextInput';
import { EMessages } from 'utils';
import CustomerContext from '../CustomerContext';
import { AuthContext } from 'components/Auth';
import { ERoles } from 'modules/Manage/ManageInterfaces';


const STEP_PREFIX = 'sc--details';

export interface IDetailsProps {
	handleInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Details: React.FunctionComponent<IDetailsProps> = (props: IDetailsProps) => {
	const AuthCTX = React.useContext(AuthContext);

	const { handleInput } = props;
	const { state } = React.useContext(CustomerContext);

	const { companyName, contactName, contactPhoneNumber, contactEmail, serviceContactEmail, serviceContactPhone } = state.inputValidation.details;

	return (
		<div className='sc--createflow-step details'>
			<div className='sc--createflow-column'>
				<div className={`${STEP_PREFIX}-title title`}>Contact information</div>
				<TextInput
					{...{
						disabled: AuthCTX.claims.role !== ERoles.MANAGER,
						labelText: 'Company name *',
						id: `${state.mode.toLowerCase()}--customer-company-name`,
						name: 'companyName',
						onChange: handleInput,
						onBlur: handleInput,
						invalid: companyName && companyName.inValid,
						invalidText: EMessages.REQUIRED,
						value: state.customer.companyName,
						required: true
					}} />
				<TextInput
					{...{
						disabled: AuthCTX.claims.role !== ERoles.MANAGER,
						labelText: 'Contact name *',
						id: `${state.mode.toLowerCase()}--customer-contact-name`,
						name: 'contactName',
						onChange: handleInput,
						onBlur: handleInput,
						invalid: contactName && contactName.inValid,
						invalidText: EMessages.REQUIRED,
						value: state.customer!.contactName,
						required: true
					}} />

				<CustomTextInput
					{...{
						disabled: AuthCTX.claims.role !== ERoles.MANAGER,
						placeholder: '1234 123 123',
						id: `${state.mode.toLowerCase()}--customer-contact-phone-number`,
						labelText: 'Phone number *',
						format: '#### ### ###',
						name: 'contactPhoneNumber',
						onChange: handleInput,
						onBlur: handleInput,
						value: state.customer.contactPhoneNumber as string,
						invalid: contactPhoneNumber && contactPhoneNumber.inValid,
						invalidText: state.customer.contactPhoneNumber.length ? EMessages.PHONE_NUMBER_INVALID : EMessages.REQUIRED,
						required: true
					}} />

				<TextInput
					{...{
						disabled: AuthCTX.claims.role !== ERoles.MANAGER,
						labelText: 'Contact Email *',
						id: `${state.mode.toLowerCase()}--customer-contact-email`,
						placeholder: 'name@customer.com',
						name: 'contactEmail',
						onChange: handleInput,
						onBlur: handleInput,
						invalid: contactEmail && contactEmail.inValid,
						invalidText: state.customer.contactEmail.length ? EMessages.EMAIL_INVALID : EMessages.REQUIRED,
						value: state.customer.contactEmail
					}}
				/>
			</div>
			<div className='sc--createflow-column'>
				<div className={`${STEP_PREFIX}-title title`}>Service contact details (optional)</div>
				<TextInput
					{...{
						disabled: AuthCTX.claims.role !== ERoles.MANAGER,
						labelText: 'Service contact Name',
						id: `${state.mode.toLowerCase()}--customer-service-contact-name`,
						placeholder: 'Service contacts full name',
						name: 'serviceContactName',
						onChange: handleInput,
						value: state.customer.serviceContactName
					}}
				/>

				<CustomTextInput
					{...{
						disabled: AuthCTX.claims.role !== ERoles.MANAGER,
						placeholder: '1234 123 123',
						id: `${state.mode.toLowerCase()}--service-contact-phone-number`,
						labelText: 'Service contact phone number',
						format: '#### ### ###',
						name: 'serviceContactPhone',
						onChange: handleInput,
						onBlur: handleInput,
						invalid: serviceContactPhone && serviceContactPhone.inValid,
						value: state.customer.serviceContactPhone as string,
						invalidText: state.customer.serviceContactPhone && state.customer.serviceContactPhone.length ? EMessages.PHONE_NUMBER_INVALID : EMessages.REQUIRED
					}} />

				<TextInput
					{...{
						disabled: AuthCTX.claims.role !== ERoles.MANAGER,
						labelText: 'Service contact Email',
						id: `${state.mode.toLowerCase()}--customer-service-contact-email`,
						placeholder: 'service-contact@customer.com',
						name: 'serviceContactEmail',
						onChange: handleInput,
						onBlur: handleInput,
						invalid: serviceContactEmail && serviceContactEmail.inValid,
						value: state.customer.serviceContactEmail,
						invalidText: EMessages.EMAIL_INVALID
					}}
				/>

			</div>
		</div>
	);
};

export { Details };
