/* eslint-disable react/display-name */
import React from 'react';
import { Modal, TextArea } from '@carbon/ibm-security';
import { CreateFlowContainer } from 'components/CreateFlow';
import { DetailsStep } from './DetailsStep';
import { IManageProps, ManageStep } from './ManageStep';
import RequestContext from '../RequestsContext';
import isEqual from 'lodash/isEqual';
import { IRequest, ESwitch, EStatus, ERequestFlowLabels } from '../RequestInterfaces';
import { HistoryStep } from './HistoryStep';
import { requestStatusChangeMap } from '../RequestUtilities';
import { EMode } from 'modules/Customers/interface';

const scPrefix = 'sc';

const handleRequestSteps = (requestStatus: EStatus): string[] => {
	const staticLabels: string[] = [ ERequestFlowLabels.Details, ERequestFlowLabels.History, ERequestFlowLabels.Manage ];

	// if (requestStatus === EStatus.PENDING_APPROVAL) {
	// 	return staticLabels;
	// }

	// staticLabels.splice(1, 0, ERequestFlowLabels.History);

	if (requestStatus === EStatus.CANCELLED) {
		return staticLabels.filter((label: string) => { return label !== ERequestFlowLabels.Manage });
	}



	return staticLabels;

}

const RequestFlowContainer: React.FunctionComponent = () => {

	const RequestCTX = React.useContext(RequestContext)
	const [ currentStep, setCurrentStep ] = React.useState<number>(0);
	const [ radioButtonState, setRadioButtonState ] = React.useState<string>('');
	const [ isDisabled, setIsDisabled ] = React.useState<boolean>(false);
	const [ isModalOpen, setIsModalOpen ] = React.useState<boolean>(false);
	const [ currentRequest, setCurrentRequest ] = React.useState<IRequest>({} as IRequest);
	const [ comment, setComment ] = React.useState<string>('');
	const [ defaultRadioValue ] = React.useState<string>();
	const [ requestInfoModal, setReqeustInfoModal ] = React.useState<boolean>(false);
	const [ requestLabels, setRequestLabels ] = React.useState<string[]>([]);


	React.useEffect(() => {
		if (RequestCTX) {
			setCurrentRequest(RequestCTX.state.customerRequest);
			setRequestLabels(handleRequestSteps(RequestCTX.state.customerRequest.status));
		}
		if (RequestCTX.state.customerRequest.status === EStatus.COMPLETED ||
			RequestCTX.state.customerRequest.status === EStatus.CONTRACTOR_COMPLETED
		) {
			RequestCTX.getRequestInvoicing();
		}

	}, [])

	const isLastStep = React.useCallback((): boolean => {
		return currentStep === requestLabels.length - 1;
	}, [ currentStep, requestLabels ]);

	const handleRadioButtonState = React.useCallback((actionValue: string): void => {
		setRadioButtonState(actionValue);
		RequestCTX.dispatch({
			type: 'editCustomerRequest',
			value: { status: actionValue }
		});

		if (actionValue === EStatus.REQUEST_FOR_INFORMATION) {
			setReqeustInfoModal(true);
		}

	}, [ setRadioButtonState, RequestCTX, setReqeustInfoModal ])

	const handleComment = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		RequestCTX.dispatch({
			type: 'editCustomerRequest',
			value: { requestComment: event.target.value }
		})
	}, [ setComment ]);

	React.useEffect(() => {
		if (isLastStep()) {
			if (RequestCTX.state.customerRequest.status === EStatus.CANCELLED && !RequestCTX.state.customerRequest.requestComment ||
				RequestCTX.state.customerRequest.status === EStatus.REQUEST_FOR_INFORMATION && !RequestCTX.state.internalMessageDescription
			) {
				setIsDisabled(true);
				return;
			}

			if (!isEqual(RequestCTX.state.customerRequest.purchaseOrder, currentRequest.purchaseOrder)) {
				return setIsDisabled(false);
			}

			switch (RequestCTX.state.requestType) {
				case ESwitch.PENDING:
					if (radioButtonState) {

						setIsDisabled(false)
					} else {
						setIsDisabled(true)
					}
					break;
				case ESwitch.OPEN:
					setIsDisabled(true)
					break;
				case ESwitch.CLOSED:
					setIsDisabled(true)
					break;

				default:
					break;
			}
		} else {
			setIsDisabled(false);
		}

	}, [ setIsDisabled, RequestCTX, currentStep, radioButtonState, currentRequest ])

	const handleProgressStep = React.useCallback((step: number) => {
		if (step === currentStep) {
			setCurrentStep(step = step + 1);
		} else {
			setCurrentStep(step);
		}
	}, [ currentStep, setCurrentStep ]);

	const closeFlowView = React.useCallback(() => {
		RequestCTX.dispatch({
			type: 'initialState'
		})
	}, [ RequestCTX ]);

	const handleCreateFlowView = React.useCallback((close?: boolean): void => {
		if (close) {
			closeFlowView();
		}

		// Drop newNotes properties as they could change due to
		// event listeners at anytime affecting the isEqual condition
		// which would show the unsaved changes modal.
		const dropNewNotesProperties = (request: IRequest): IRequest => {
			const { newNotesHq, newNotesContractor, newNotesCustomer, ...rest } = request;
			return rest;
		}

		if (!isEqual(dropNewNotesProperties(currentRequest), dropNewNotesProperties(RequestCTX.state.customerRequest))) {
			setIsModalOpen(true);
		} else {
			closeFlowView();
		}
	}, [ setIsModalOpen, closeFlowView, currentRequest ]);

	const renderDangerModal = (): JSX.Element => {
		return (
			<Modal
				className={`${scPrefix}--modal-danger`}
				danger={true}
				open={isModalOpen}
				iconDescription={'Close the modal'}
				primaryButtonText={'Close without saving'}
				secondaryButtonText={'Continue editing'}
				modalHeading={'You have unsaved changes.'}
				shouldSubmitOnEnter={true}
				onRequestClose={() => setIsModalOpen(false)}
				onRequestSubmit={closeFlowView}
				onSecondarySubmit={() => setIsModalOpen(false)}
			>
				<div>
					<p className='sc--composed-modal--body'>{'If you\'d like to keep editing press the Continue editing button.'}</p>
				</div>
			</Modal>
		)
	}

	const handleRequestInfoTextMessage = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
		RequestCTX.dispatch({
			type: 'internalMessageDescription',
			value: event.target.value
		})
	}, [ RequestCTX ]);

	const handleRequestInfoState = React.useCallback((state: boolean) => {
		if (!state) {
			setReqeustInfoModal(false);
			setRadioButtonState('');
			RequestCTX.dispatch({
				type: 'internalMessageDescription',
				value: ''
			});
		}
	}, [ setReqeustInfoModal, RequestCTX, setRadioButtonState ])

	const renderInfoModal = (): JSX.Element => {
		return (
			<Modal
				className={`${scPrefix}--modal-request-info`}
				open={requestInfoModal}
				iconDescription={'Close the modal'}
				primaryButtonText={'Continue'}
				secondaryButtonText={'Cancel'}
				modalHeading={'Request for more infomation'}
				shouldSubmitOnEnter={true}
				onRequestClose={() => handleRequestInfoState(false)}
				onRequestSubmit={() => setReqeustInfoModal(false)}
				onSecondarySubmit={() => handleRequestInfoState(false)}
				value={RequestCTX.state.internalMessageDescription}
				primaryButtonDisabled={!RequestCTX.state.internalMessageDescription}
			>
				<div>
					<p className='sc--composed-modal--body'>{'Add a message in the comment box below. This message will be added to the message history logs.'}</p>
					<TextArea
						className={'sc--request-info-textarea'}
						labelText={'Comment'}
						placeholder={'Type here'}
						invalid={RequestCTX.state.customerRequest.status === EStatus.DECLINED && !RequestCTX.state.customerRequest.requestComment}
						invalidText={'Please provide a comment as to why the request was declined.'}
						onChange={handleRequestInfoTextMessage}
						disabled={RequestCTX.state.requestType !== ESwitch.PENDING}
						value={RequestCTX.state.internalMessageDescription}
					/>
				</div>
			</Modal>
		)
	}

	const setMainTitle = React.useMemo(() => {
		const title = `<span>Request details</span>${RequestCTX.state.customerRequest.title}<span>${requestStatusChangeMap.get(currentRequest.status)}</span>`;
		return title;
	}, [ RequestCTX, currentRequest ])

	const manageProps: IManageProps = {
		comment,
		handleRadioButtonState,
		handleComment,
		selectedValue: radioButtonState,
		defaultRadioValue: defaultRadioValue as string,
		originalStatus: currentRequest.status,
		initialRequestState: currentRequest
	}

	const requestFLowSteps = React.useMemo(() => {
		return (
			[
				<DetailsStep key={ERequestFlowLabels.Details} />,
				<HistoryStep key={ERequestFlowLabels.History} />,
				<ManageStep {...manageProps} key={ERequestFlowLabels.Manage} />
			].filter((step: JSX.Element) => {
				return requestLabels.indexOf(step.key as string) > -1;
			})
		)
	}, [ requestLabels, manageProps ])

	const renderSteps = () => {
		return (
			requestFLowSteps.map((content, index): JSX.Element | null => (index === currentStep ? content : null))
		)
	}

	return (
		<React.Fragment>
			{renderDangerModal()}
			{renderInfoModal()}
			<CreateFlowContainer
				{...{
					title: setMainTitle,
					currentStep: currentStep,
					handleProgressStep: handleProgressStep,
					handleSubmit: RequestCTX.updateRequest,
					handleCreateFlowView: handleCreateFlowView,
					isLastStep: isLastStep,
					shouldEnableSave: isDisabled,
					mode: EMode.EDIT, //TODO: request type should set the create flow to one of three states.
					requestStatus: RequestCTX.state.customerRequest.status,
					stepsType: requestLabels,
					renderMain: renderSteps,
					newNotesHq: RequestCTX.state.customerRequest.newNotesHq
				}}
			/>
		</React.Fragment>
	)
}

export { RequestFlowContainer };