import React from 'react';
import { Helmet } from 'react-helmet';

export interface IDocumentTitleProps {
	title: string;
}
const DocumentTitle: React.FunctionComponent<IDocumentTitleProps> = (props) => {
	return (
		<Helmet>
			<title>{ `${props.title} | SimplyClik admin`}</title>
		</Helmet>
	);
}

export {
	DocumentTitle
}
