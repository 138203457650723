import React from 'react';
import { HeaderNavigation, HeaderGlobalBar } from 'carbon-components-react/lib/components/UIShell';
import * as ROUTES from '../utils/routes';
import { NavLink, useHistory } from 'react-router-dom';
import { AuthContext } from 'components/Auth';
import { FirebaseContext } from 'components/Firebase';
import { Button } from '@carbon/ibm-security';
import { Logout24 } from '@carbon/icons-react';
interface INavLink {
	route: string;
	label: string;
}

const NAV_LINKS: INavLink[] = [
	{
		route: ROUTES.ADMIN_ACTIVITY,
		label: 'Activity'
	},
	{
		route: ROUTES.ADMIN_CUSTOMERS,
		label: 'Service Locations'
	},
	{
		route: ROUTES.ADMIN_REQUESTS,
		label: 'Requests'
	},
	{
		route: ROUTES.ADMIN_MANAGE,
		label: 'Manage'
	}
]


const HeaderNavWrapper: React.FunctionComponent = () => {

	const FBContext = React.useContext(FirebaseContext);
	const history = useHistory();

	const { authUser } = React.useContext(AuthContext);

	const handleSignout = React.useCallback(() => {
		history.push(ROUTES.SIGN_OUT)
		FBContext && FBContext.auth.signOut();
	}, [ history ])
	return (
		authUser ? (
			<React.Fragment>
				<HeaderNavigation aria-label='Simplyclik'>
					{NAV_LINKS.map((link: INavLink) => (
						<li key={`sc--nav-item-${link.label}`}>
							<NavLink
								role='menuitem'
								className='sc--nav-item bx--header__menu-item'
								activeClassName='active'
								to={link.route}>
								{link.label}
							</NavLink>
						</li>
					))}
				</HeaderNavigation>
				<HeaderGlobalBar>
					<a
						role='menuitem'
						className='sc--nav-item bx--header__menu-item'
						href="mailto:support@simplyclik.com">
						Support
					</a>
				</HeaderGlobalBar>
				<Button kind='secondary' renderIcon={Logout24} iconDescription='Sign-out' tooltipPosition='left' hasIconOnly onClick={handleSignout} />
			</React.Fragment>
		) : (
			<HeaderGlobalBar>
				<a
					role='menuitem'
					className='sc--nav-item bx--header__menu-item'
					href="mailto:support@simplyclik.com">
					Support
				</a>
			</HeaderGlobalBar>
		)
	);
}

export {
	HeaderNavWrapper
}
