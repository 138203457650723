import './styles/index.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { FirebaseContext } from './components/Firebase';
import Firebase from './components/Firebase/Firebase';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<FirebaseContext.Provider value={new Firebase()}>
		<App />
	</FirebaseContext.Provider>,
	document.getElementById('root')
);

serviceWorker.unregister();
