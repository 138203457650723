
/* eslint-disable no-useless-escape */
import React from 'react';
import NumberFormat from 'react-number-format';
import { WarningFilled16 } from '@carbon/icons-react'
import { EMessages } from 'utils';
export interface IInputTextFormatProps {
	disabled?: boolean;
	placeholder?: string;
	format: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	labelText: string;
	name: string;
	value: string;
	id?: string;
	required?: boolean;
	invalid?: boolean;
	invalidText?: string;
}

const CustomTextInput: React.FunctionComponent<IInputTextFormatProps> = (props: IInputTextFormatProps) => {

	return (
		<div className='bx--form-item bx--text-input-wrapper'>
			<label htmlFor={props.id} className='bx--label'>{props.labelText}</label>
			{props.invalid ? (
				<React.Fragment>
					<div className='bx--text-input__field-wrapper' data-invalid={true}>
						<WarningFilled16 className='bx--text-input__invalid-icon' />
						<NumberFormat
							disabled={props.disabled}
							placeholder={props.placeholder}
							className='bx--text-input'
							onChange={props.onChange}
							onBlur={props.onBlur}
							name={props.name}
							value={props.value}
							id={props.id}
							// invalid={props.invalid}
							required={props.required}
							format={props.format} />
					</div>
					<div className='bx--form-requirement' id={`${props.id}-error-msg`}>{props.invalidText || EMessages.REQUIRED}</div>
				</React.Fragment>
			)
				:
				(
					<div className='bx--text-input__field-wrapper'>
						<NumberFormat
							disabled={props.disabled}
							placeholder={props.placeholder}
							className='bx--text-input'
							onChange={props.onChange}
							onBlur={props.onBlur}
							name={props.name}
							value={props.value}
							id={props.id}
							required={props.required}
							format={props.format} />
					</div>
				)}
		</div>
	);
};

export { CustomTextInput };
