/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { FirebaseContext } from 'components/Firebase';
import RequestContext from './RequestsContext';
import { AuthContext } from 'components/Auth';
import { RequestReducer } from './RequestReducer';
import { IRequest, IContentSwitch, IRequestReducerInitialState, IRequestContextProps, ESwitch, ICustomerLocationContractor, EStatus, IContractor, IRequestLog, ERequestVisibility } from './RequestInterfaces';
import { ContentSwitcher, Switch, InlineNotification } from '@carbon/ibm-security';
import { RequestTiles } from './RequestTiles';
import { getStatusTypes, contractorPriorityOrder, generateLogEntry } from './RequestUtilities';
import { RequestFlowContainer } from './RequestFlow';
import { Loader } from 'components/Loader';
import firebase from 'firebase';

const classPrefix = 'sc--request-flow';

const initialState: IRequestReducerInitialState = {
	customerRequests: [] as IRequest[],
	customerRequest: {} as IRequest,
	internalMessageDescription: '',
	requestContractors: [],
	contractorPriority: [],
	requestType: ESwitch.PENDING,
	requestStatusColor: 'red'
}

interface ILoader {
	loading: boolean;
	message?: string;
}

const defaultLoader: ILoader = {
	loading: false,
	message: ''
}

interface IInlineNotificationProps {
	notificationType: string;
	onCloseButtonClick?: () => void;
	className: string;
	kind: string; //TODO: need global interface for kind prop
	title: string;
	subtitle: string;
	iconDescription?: string;
	isActive: boolean;
}

const defaultNotificationProps: IInlineNotificationProps = {
	notificationType: 'inline',
	className: 'sc--notification',
	kind: 'info',
	title: '',
	subtitle: '',
	isActive: false
}

const RequestsContainerBase: React.FunctionComponent = () => {

	const FirebaseCTX = React.useContext(FirebaseContext);
	const AuthCTX = React.useContext(AuthContext);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const docSubscribers = [] as any;
	const [ contentSwitch, setContentSwitch ] = React.useState<string[]>(getStatusTypes(ESwitch.PENDING))
	const [ index, setIndex ] = React.useState<number>(0);
	const [ state, dispatch ] = React.useReducer(RequestReducer, initialState);
	const [ isLoading, setIsLoading ] = React.useState<ILoader>(defaultLoader);
	const [ requestId, setRequestId ] = React.useState<string>('');
	const [ notification, setNotification ] = React.useState<IInlineNotificationProps>(defaultNotificationProps);
	/**
	 * inlineNotificationClickHandler function to append
	 * the onClickButton function to defaultNotificationProps
	 */
	const inlineNotificationCickHandler = React.useCallback(() => {
		setNotification(defaultNotificationProps)
	}, [ setNotification ]);
	// Component mount set the onCloseButtonClick handler
	React.useEffect(() => {
		setNotification(prevState => {
			return {
				...prevState,
				onCloseButtonClick: inlineNotificationCickHandler
			}
		})

		// Unsubscribe listeners.
		return () => {
			docSubscribers.map((subscriber) => (subscriber()));
		}
	}, []);

	const initialLoad = React.useCallback(async () => {
		if (AuthCTX.claims && AuthCTX.claims.customerRef && FirebaseCTX) {
			const requestRefSubscriber = await FirebaseCTX.requests()
				.where('customer.id', '==', `${AuthCTX.claims.customerRef}`)
				.orderBy('status')
				.orderBy('requestStartDate', 'desc')

			requestRefSubscriber.onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
				const customerRequests: IRequest[] = [];
				for (const documentSnapshot of snapshot.docs) {
					const requestId: string = documentSnapshot.id;
					const docCollection = { ...documentSnapshot.data(), ...{ docId: requestId } }
					customerRequests.push(docCollection as IRequest)
				}
				dispatch({
					type: 'customerRequests',
					value: customerRequests
				});
			});
		}
	}, [ AuthCTX.claims, dispatch ]);

	const handleContentSwitcher = React.useCallback((contentSwitchValue: IContentSwitch) => {
		const statusTypes: string[] | undefined = getStatusTypes(contentSwitchValue.name);
		if (statusTypes) {
			setContentSwitch(statusTypes);
			setIndex(contentSwitchValue.index)
			dispatch({
				type: 'requestState',
				value: {
					// requestStatusColor: setStatusTagColor(contentSwitchValue.name),
					requestType: contentSwitchValue.name
				}
			});
		}

	}, [ setContentSwitch, setIndex, dispatch ]);

	const requestContractors = React.useCallback((customerLocationContractors: ICustomerLocationContractor[], serviceType: string) => {

		const tempContractorArray: ICustomerLocationContractor[] =
			customerLocationContractors.filter((contractor: ICustomerLocationContractor) => {
				return contractor.contractorServices.includes(serviceType);
			})

		dispatch({
			type: 'requestContractors',
			value: tempContractorArray
		});
		dispatch({
			type: 'contractorPriority',
			value: contractorPriorityOrder(tempContractorArray)
		});
		// 	})

	}, [ setIsLoading, dispatch ]);

	/**
	 * Only called when requests are in "Pending Approval" state
	 * Get all contractors assigned to that customer location and filter out
	 * contractors that haven't yet verified with the mobile app
	 */
	const getCustomerLocationContractors = React.useCallback((requestedInfo: IRequest) => {
		let contractorServiceTypes: string | string[] = requestedInfo.serviceType;
		const requestedContractors = [];
		if (!Array.isArray(contractorServiceTypes)) {
			contractorServiceTypes = new Array(contractorServiceTypes);
		}

		setIsLoading({ loading: true });

		if (FirebaseCTX && requestedInfo.customerLocation.id) {
			const customerLocationContractors = FirebaseCTX.customerLocationContractors()
				.where('customerLocationId', 'array-contains', requestedInfo.customerLocation.id)

			customerLocationContractors.onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
				const reads: ICustomerLocationContractor[] = [];
				if (snapshot.size > 0) {
					snapshot.forEach((docSnapshot) => {
						reads.push(docSnapshot.data() as ICustomerLocationContractor)
					});
					if (reads.length > 0) {
						requestContractors(reads, requestedInfo.serviceType)
					}
				} else {

					dispatch({
						type: 'requestContractors',
						value: requestedContractors
					});
				}
			});
		}
		setIsLoading({ loading: false });

	}, [ dispatch, setIsLoading ]);

	const getCustomerRequestDetails = React.useCallback((requestedInfo?: IRequest) => {
		setIsLoading({ loading: true });
		if (requestedInfo && FirebaseCTX) {
			FirebaseCTX.storage.ref(`images/requests/${requestedInfo.docId}`)
				.listAll()
				.then((result) => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					return Promise.all(result.items.map((item: any) => {
						return item.getDownloadURL()
							.then((src) => {
								return src;
							})
					}));
				})
				.then(async (result) => {
					requestedInfo.requestedImagePaths = result;
					setRequestId(requestedInfo.docId);
					setIsLoading({ loading: false });
					await dispatch({
						type: 'customerRequest',
						value: requestedInfo
					});
					dispatch({
						type: 'requestFlowEnabled',
						value: true
					})
				})
				.then(() => {
					if (requestedInfo.status === EStatus.PENDING_APPROVAL || requestedInfo.status === EStatus.REQUEST_FOR_INFORMATION) {
						getCustomerLocationContractors(requestedInfo);
					}
				})
		}

	}, [ setIsLoading, dispatch, setRequestId ]);

	const setRequestContractorPriority = React.useCallback(() => {
		setIsLoading({ loading: true, message: 'Sending request to contractor...' })
		if (FirebaseCTX && state.contractorPriority.length) {
			FirebaseCTX.requestContractorPriority()
				.doc(requestId)
				.set({ contractors: state.contractorPriority }, { merge: true })
				.then(() => {
					const timer = setTimeout(() => {
						dispatch({
							type: 'initialState'
						})
						setIsLoading({ loading: false });
					}, 2000);
					return () => clearTimeout(timer);

				})
				.catch((err) => {
					console.log('ERROR', err);
					setIsLoading({ loading: false });
				})
		}
	}, [ state, dispatch, requestId, setIsLoading ]);

	const setVisibilityForLogging = (requestStatus: EStatus): ERequestVisibility => {
		let visibility: ERequestVisibility = ERequestVisibility.Public;
		if (requestStatus === EStatus.CANCELLED) {
			// TODO: need to confirm this is the right visibility for cancelled requests.
			visibility = ERequestVisibility.Private;
		} else if (requestStatus === EStatus.REQUEST_FOR_INFORMATION) {
			visibility = ERequestVisibility.Internal
		}
		return visibility;
	}

	const logRequestHistory = React.useCallback((docId: string) => {
		setIsLoading({ loading: true, message: 'Updating history record...' });
		const visibility: ERequestVisibility = setVisibilityForLogging(state.customerRequest.status);

		if (FirebaseCTX) {
			const newLogEntry = generateLogEntry(state.internalMessageDescription, state.customerRequest.customerLocation.id, state.customerRequest.status, visibility)
			const batch = FirebaseCTX.batch();
			const requestsRef = FirebaseCTX.requests().doc(docId);
			const notesRef = requestsRef.collection('history');

			batch.set(notesRef.doc(), newLogEntry);

			// If request was cancelled add the comment as a batch item for the history logs along with the status update.
			if (state.customerRequest.status === EStatus.CANCELLED && state.customerRequest.requestComment.length) {
				const additionalLogEntry = generateLogEntry(state.customerRequest.requestComment, state.customerRequest.customerLocation.id, state.customerRequest.status, ERequestVisibility.Private);
				batch.set(notesRef.doc(), additionalLogEntry)
			}


			batch.commit()
				.then(() => {
					setTimeout(() => {
						dispatch({
							type: 'initialState'
						})
					}, 1200);
				})
				.catch((error) => {
					return error;
				})
				.finally(() => {
					setTimeout(() => {
						setIsLoading({ loading: false });

					}, 1500);
				})
		}
	}, [ state, setIsLoading, dispatch ]);


	const newNoteHqSeen = React.useCallback(() => {
		const { docId, ...request } = state.customerRequest;
		if (FirebaseCTX) {
			FirebaseCTX.requests()
				.doc(requestId)
				.set(request)
				.catch((err) => {
					console.log(err)
				});
		}
	}, [ requestId, state ]);

	const updateRequest = React.useCallback((): void => {
		let tempRequest: IRequest = {} as IRequest;
		const { status } = state.customerRequest;

		if (status === EStatus.AWAITING_ACCEPTANCE || status === EStatus.AWAITING_QUOTE) {
			if (state.requestContractors.length === 0) {
				const notificationProps = {
					kind: 'error',
					title: 'Error:',
					subtitle: 'You\'re trying to perform a prohibited action for this request.',
					isActive: true
				}
				setNotification(prevState => {
					return {
						...prevState,
						...notificationProps
					}
				});
				return;
			}

			//Assign first contractor in the requestContractors array
			const approvedContractor = state.requestContractors[ 0 ] as ICustomerLocationContractor
			const contractor: IContractor = {
				companyName: approvedContractor.contractorCompanyName,
				contactName: approvedContractor.contractorContactName,
				id: approvedContractor.contractorId,
				contactPhoneNumber: approvedContractor.contractorContactPhone
			}
			const contractorAssignedDate: any = firebase.firestore.Timestamp.fromDate(new Date());
			tempRequest = { ...state.customerRequest, contractorAssignedDate, contractor };
		} else {
			tempRequest = { ...state.customerRequest }
		}

		//Spread tempRequest to remove docId as the operand of a 'delete' operator must be optional
		// eslint-disable-next-line no-unused-vars

		const { docId, ...setRequest } = tempRequest;
		if (FirebaseCTX) {
			FirebaseCTX.requests()
				.doc(requestId)
				.set(setRequest)
				.then(() => {
					if (status === EStatus.AWAITING_QUOTE) {
						setRequestContractorPriority();
					}
					logRequestHistory(docId);
				})
				.catch((err) => {
					console.log(err)
				});
		}
	}, [ requestId, state, setNotification ]);



	const getRequestInvoicing = React.useCallback((): void => {
		const { docId } = state.customerRequest
		setIsLoading({ loading: true, message: 'Request invoicing details...' });
		if (FirebaseCTX) {
			const requestInvoicingSubscriber = FirebaseCTX.requestInvoicing().doc(docId);
			requestInvoicingSubscriber.onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
				dispatch({
					type: 'requestInvoicing',
					value: snapshot.data()
				})
			});

			// .then((documentSnapshot: firebase.firestore.DocumentSnapshot) => {
			// 	if (documentSnapshot.exists) {
			// 		dispatch({
			// 			type: 'requestInvoicing',
			// 			value: documentSnapshot.data()
			// 		})
			// 	}
			// })
			// .finally(() => {
			// })
			docSubscribers.push(requestInvoicingSubscriber)
			setIsLoading(defaultLoader);
		}
	}, [ state, setIsLoading, docSubscribers ]);
	/**
	 * @function getCustomerRequestNotes query request notes subcollection
	 * to filter customers requests notes related to the customer location.
	 * @param docId requests collection UID
	 */
	const getCustomerRequestNotes = React.useCallback((docId: string): void => {
		setIsLoading({ loading: true, message: 'Retrieving request notes...' });
		if (FirebaseCTX) {
			const requestNotes = FirebaseCTX.requests().doc(docId).collection('history').orderBy('addedDate', 'desc');

			const requestNotesSubscriber = requestNotes.onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
				const requestHistory: IRequestLog[] = [];
				for (const documentSnapshot of snapshot.docs) {
					requestHistory.push(documentSnapshot.data() as IRequestLog)
				}
				dispatch({
					type: 'customerRequestLogs',
					value: requestHistory
				});
				setTimeout(() => {
					setIsLoading(defaultLoader);
				}, 1000);
			});

			docSubscribers.push(requestNotesSubscriber)
		}
	}, [ dispatch, setIsLoading, docSubscribers ]);

	const logPrivateMessage = React.useCallback(() => {
		const { docId } = state.customerRequest;
		setIsLoading({ loading: true, message: 'Updating request logs...' });
		if (FirebaseCTX) {
			const newLogEntry = generateLogEntry(state.privateMessage, state.customerRequest.customerLocation.id, state.customerRequest.status, ERequestVisibility.Private)
			const requestsRef = FirebaseCTX.requests().doc(docId);
			const notesRef = requestsRef.collection('history');

			notesRef.add({
				...newLogEntry
			})
				.then(() => {
					dispatch({
						type: 'privateMessage',
						value: ''
					})
				})
				.catch((error) => {
					console.log('error', error)
				})
				.finally(() => {
					setTimeout(() => {
						setIsLoading(defaultLoader);
					}, 1000);
				})
		}
	}, [ state, setIsLoading ]);

	React.useEffect(() => {
		if (AuthCTX.claims) {
			initialLoad();
		}
	}, []);

	const requestContextProps = {
		getCustomerRequestDetails,
		getCustomerRequestNotes,
		updateRequest,
		newNoteHqSeen,
		logPrivateMessage,
		getRequestInvoicing
	}
	const contextValue: IRequestContextProps = React.useMemo(() => {
		return {
			...requestContextProps,
			state,
			dispatch
		};
	}, [ state, dispatch, requestContextProps ]);

	return (
		<RequestContext.Provider value={contextValue} >
			{isLoading.loading && (<Loader message={isLoading.message} />)}
			{state.requestFlowEnabled ? (
				<RequestFlowContainer />
			) : (
				<div className='bx--grid'>
					<div className={'sc--main-title'}>
						<h1>Requests</h1>
					</div>
					<ContentSwitcher
						className={`${classPrefix}-content-switcher`}
						selectedIndex={index}
						onChange={handleContentSwitcher}>
						<Switch name={ESwitch.PENDING} text={ESwitch.PENDING} />
						<Switch name={ESwitch.OPEN} text={ESwitch.OPEN} />
						<Switch name={ESwitch.CLOSED} text={ESwitch.CLOSED} />
					</ContentSwitcher>
					<RequestTiles {...{
						requestStatus: contentSwitch as string[]
					}} />
				</div>
			)}
			{notification.isActive ?
				<InlineNotification {...notification} />
				: null}
		</RequestContext.Provider>
	)
}

export { RequestsContainerBase as RequestsContainer };
