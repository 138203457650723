import { GetPastTime } from 'utils/time';

enum EDateRange {
	WeekAgo = '7',
	TwoWeeksAgo = '14',
	OneMonthAgo = '30'
}

export const getDateRange = (value: EDateRange = EDateRange.OneMonthAgo): number => {
	let range: number;
	if (value === EDateRange.WeekAgo) {
		range = GetPastTime(EDateRange.WeekAgo);
	} else if (value === EDateRange.TwoWeeksAgo) {
		range = GetPastTime(EDateRange.TwoWeeksAgo);
	} else if (value === EDateRange.OneMonthAgo) {
		range = GetPastTime(EDateRange.OneMonthAgo);
	} else {
		console.log('Error setting Date range')
		return value;
	}
	return range;
}

export {
	EDateRange
}