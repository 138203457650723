/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { FirebaseContext } from 'components/Firebase';
import { ManageReducer } from './ManageReducer';
import ManageContext from './ManageContext';
import { ManageTable } from './ManageTable';
import { AddEditUser } from './AddEditUser';
import { IManageReducerInitalState, ICustomerUserDetails, IManageContextProps } from './ManageInterfaces';
import { AuthContext } from 'components/Auth';

const initialState: IManageReducerInitalState = {
	addEditViewEnabled: false,
	manageCustomerUsers: [] as ICustomerUserDetails[],
	manageCustomerUser: {} as ICustomerUserDetails,
	mode: '',
	tableDataLoaded: false
};

const ManageContainer: React.FunctionComponent = () => {
	const FirebaseCTX = React.useContext(FirebaseContext);
	const AuthCTX = React.useContext(AuthContext);

	const [ state, dispatch ] = React.useReducer(ManageReducer, initialState);
	// const [userRole, setUserRole] = React.useState<string>('')
	const [ isLoading, setIsLoading ] = React.useState<boolean>(false);

	const manageContextProps = {
		isLoading
	}

	const contextValue: IManageContextProps = React.useMemo(() => {
		return {
			...manageContextProps,
			state,
			dispatch
		};
	}, [ state, dispatch, manageContextProps ]);

	//Initial component mount
	const initialLoad = React.useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		// setIsLoading(true);
		const manageCustomerUsers = [] as any;
		if (AuthCTX.claims && AuthCTX.claims.customerRef && FirebaseCTX) {
			FirebaseCTX
				.customerUsers()
				.doc(`${AuthCTX.claims.customerRef}`)
				.collection('users')
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						const tempObj = Object.assign({ id: doc.id }, doc.data());
						manageCustomerUsers.push(tempObj);
					});
					dispatch({
						type: 'manageCustomerUsers',
						value: manageCustomerUsers
					});
				});
		}

	}, [ setIsLoading, dispatch, AuthCTX.claims ]);

	React.useEffect(() => {
		const abortController = new AbortController()

		if (AuthCTX.claims) {
			initialLoad();
		}
		return () => (
			abortController.abort()
		);

	}, [ AuthCTX ]);



	// React.useMemo(() => {
	// 	if(AuthCTX.claims){
	// 		const { role, customerRef } = AuthCTX.claims
	// 		setUserRole(role)
	// 		setCustomerId(customerRef)
	// 	}
	// }, [setUserRole, userRole, AuthCTX.claims])

	const createNewUser = React.useCallback(() => {
		setIsLoading(true);

		if (FirebaseCTX) {
			const createCustomerUser = FirebaseCTX.functions.httpsCallable('createCustomerUser')
			const { manageCustomerUser } = state;

			const data = {
				name: manageCustomerUser.name,
				phone: manageCustomerUser.phone,
				email: manageCustomerUser.email,
				role: manageCustomerUser.role,
				customerRef: AuthCTX.claims.customerRef
			}
			return createCustomerUser(data)
				.then(() => {
					initialLoad();
					return;
				})
				.catch((error) => {
					return Promise.reject(error.message);
				})
				.finally(() => {
					setIsLoading(false)
				})
		}

		return;

	}, [ AuthCTX.claims.customerRef, isLoading, state, dispatch ]);

	const updateUser = React.useCallback(() => {
		setIsLoading(true);

		if (FirebaseCTX) {
			const updateCustomerUser = FirebaseCTX.functions.httpsCallable('updateCustomerUser')
			const { manageCustomerUser } = state;
			const id = manageCustomerUser.id;
			const originalCustomerDetails = state.manageCustomerUsers.find((customer) => { return customer.id === id });

			const data = {
				name: manageCustomerUser.name,
				phone: manageCustomerUser.phone,
				email: manageCustomerUser.email,
				role: manageCustomerUser.role,
				originalCustomerDetails,
				customerRef: AuthCTX.claims.customerRef
			}
			return updateCustomerUser(data)
				.then(() => {
					initialLoad();
					return;
				})
				.catch((error) => {
					return Promise.reject(error.message);
				})
				.finally(() => {
					setIsLoading(false)
				})
		}

		return;

	}, [ AuthCTX.claims.customerRef, state ]);

	return (
		<React.Fragment>
			<ManageContext.Provider value={contextValue}>
				{/* {state.loader.isLoading && <Loader {...state.loader}/>} */}

				<div className='bx--grid'>
					<div className={'sc--main-title'}>
						<h1>Manage</h1>
					</div>
					{state.addEditViewEnabled && (
						<AddEditUser {...{ createNewUser, updateUser }} />
					)}
					<ManageTable />
				</div>
			</ManageContext.Provider>
		</React.Fragment>
	)
}

export default ManageContainer;
