/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */

interface IRequestContextProps {
	state: IRequestReducerInitialState;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dispatch: ({ type }: { type: string; value?: any; key?: string }) => void;
	getCustomerRequestDetails: (request?: IRequest) => void;
	getCustomerRequestNotes: (docId: string) => void;
	updateRequest: () => void;
	newNoteHqSeen: () => void;
	logPrivateMessage: () => void;
	getRequestInvoicing: () => void;
}

interface IRequestReducerInitialState {
	customerRequests: IRequest[];
	customerRequest: IRequest;
	requestContractors: ICustomerLocationContractor[];
	contractorPriority: string[];
	requestType: string;
	requestStatusColor: string;
	internalMessageDescription: string;
	customerRequestLogs?: IRequestLog[];
	privateMessage?: string;
	purchaseOrdser?: string;
	requestInvoicing?: IRequestInvoicing;
}
interface IRequest {
	customer: ICustomer;
	customerLocation: ICustomerLocation;
	contractor?: IContractor;
	contractorAssignedDate?: Date;
	docId: string;
	description: string;
	invoiceAmount?: number;
	invoiceNumber?: string;
	priority: EPriorityList;
	purchaseOrder?: string;
	requestComment?: string;
	requestStartDate?: IRequestDate;
	requestEndDate?: IRequestDate;
	serviceType: string;
	status: EStatus;
	title: string;
	requestedImagePaths?: any;
	newNotesHq?: boolean;
	newNotesCustomer?: boolean;
	newNotesContractor?: boolean;
}

interface IRequestInvoicing {
	purchaseOrder: string;
	invoiceNumber: string;
	amount: number;
	submitDate: IRequestDate;
	autoApproveDate: IRequestDate;
}

interface IRequestLog {
	addedDate: {nanoseconds: number; seconds: number};
	description?: string;
	displayName: string;
	type: ELogType;
	uid: string;
	visibility: ERequestVisibility;
}

interface ICustomerLocationContractor {
	contractorContactEmail: string;
	contractorContactName: string;
	contractorContactPhone: string;
	contractorCompanyName: string;
	contractorId: string;
	contractorServices: string | string[];
	customerLocationId: string;
}

interface IRequestInvoice {
	amount: number;
	currency: string;
	customer: ICustomer;
	invoiceNumber: string;
	purchaseOrder: string;
	submitDate: string;
}

interface IContractor {
	companyName: string;
	contactName: string;
	contactPhoneNumber: string;
	id: string;
	quoteAmount?: number;
}

interface IRequestDate {
	seconds: number;
	nanoSeconds: number;
	toDate();
}

interface ICustomerLocation {
	city: string;
	companyName: string;
	contactName: string;
	contactPhoneNumber: string;
	id: string;
	reference: string;
}

interface ICustomer {
	id: string;
	logoUrl: string;
	name: string;
	paymentEmail: string;
}

interface IContentSwitch {
	index: number;
	name: string;
	text: string;
}

interface IRequestStatusMap {
	status: EStatus;
	desc: string;
	filterFor: EType;
}

enum ERequestVisibility {
	Public = 'public',
	Internal = 'internal',
	Private = 'private'
}

enum ELogType {
	Status = 'status',
	Comment = 'comment'
}

enum ERequestFlowLabels {
	Details = 'Details',
	History = 'History',
	Manage = 'Manage'
}

enum EStatus {
	ACCEPTED = 'Accepted', // Set by HQ
	AWAITING_ACCEPTANCE = 'Awaiting Acceptance', // HQ -> Contractor
	AWAITING_QUOTE = 'Awaiting Quote', // HQ -> Contractor
	CANCELLED = 'Cancelled', // Set by HQ
	COMPLETED = 'Completed', // Set by Contractor
	CONTRACTOR_COMPLETED = 'Contractor Completed', // Set by Contractor
	DECLINED = 'Declined', // Set by Contractor
	PENDING_APPROVAL = 'Pending Approval', // Waiting on HQ
	PENDING_QUOTE_APPROVAL = 'Pending Quote Approval', // Waiting on Contractor
	REQUEST_FOR_INFORMATION = 'More information needed' // HQ -> Manager
}

enum ERequestRadioLabels {
	Accept = 'Accept quote',
	AssignContractor = 'Assign contractor',
	RequestForMoreInformation = 'Request for more information',
	Cancel = 'Cancel request',
	// Decline = 'Decline quote'
}

enum ESwitch {
	PENDING = 'Pending',
	OPEN = 'Open',
	CLOSED = 'Closed',
}

enum EType {
	Manager = 'manager',
	Contractor = 'contractor'
}


enum EPriorityTypes {
	Blue = 'blue',
	Cyan = 'cyan',
	Green = 'green',
	Purple = 'purple',
	Orange = 'orange',
	Magenta = 'magenta',
	Red = 'red',
	Teal = 'teal'
}

enum EPriorityList {
	None = 'None',
	Low = 'Low',
	Medium = 'Medium',
	High = 'High',
	Urgent = 'Urgent'
}

export {
	EType,
	ERequestFlowLabels,
	EPriorityList,
	EPriorityTypes,
	ERequestVisibility,
	ELogType,
	IContractor,
	ICustomerLocationContractor,
	IRequestInvoice,
	IRequestReducerInitialState,
	IRequestDate,
	EStatus,
	ERequestRadioLabels,
	ESwitch,
	IContentSwitch,
	IRequestContextProps,
	IRequest,
	IRequestLog,
	IRequestStatusMap
}