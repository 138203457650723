/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import * as ROUTES from 'utils/routes';
import { AuthContext } from 'components/Auth';
import { FirebaseContext } from 'components/Firebase';
import { Form, FormGroup, InlineNotification, TextInput, Button, Loading } from '@carbon/ibm-security';

interface IAuthError {
	code: string;
	message: string;
}

const LoginContainer: React.FunctionComponent<any> = ({ history }) => {
	const FirebaseCTX = React.useContext(FirebaseContext);
	const { claims } = React.useContext(AuthContext);

	const [ isLoading, setLoading ] = React.useState<boolean>(true);

	const [ password, setPassword ] = React.useState<string>('');
	const [ passwordError, setPasswordError ] = React.useState<boolean>(false);
	const [ passwordErrorMessage, setPasswordErrorMessage ] = React.useState<string>('');

	const [ email, setEmail ] = React.useState<string>('');
	const [ emailError, setEmailError ] = React.useState<boolean>(false);
	const [ emailErrorMessage, setEmailErrorMessage ] = React.useState<string>('');

	const [ userHasAuthenticated, setUserHasAuthenticated ] = React.useState<boolean>();


	React.useEffect(() => {
		const abortController = new AbortController();
		document.body.classList.add('sc--login-page');
		const timer = setTimeout(() => {
			setLoading(false)
		}, 1500);
		return () => {
			clearTimeout(timer);
			abortController.abort();
			document.body.classList.remove('sc--login-page');
		}
	}, [ setLoading ]);

	const handleError = React.useCallback((err: IAuthError) => {
		const codeType = err.code;

		if (codeType.indexOf('password') !== -1) {
			setPasswordError(true);
			setPasswordErrorMessage(err.message);
		} else {
			setEmailError(true);
			setEmailErrorMessage(err.message);
		}

	}, [ setPasswordError, setEmailError, setPasswordErrorMessage, emailErrorMessage ])

	const handleLogin = React.useCallback((event: React.SyntheticEvent) => {
		event.preventDefault();
		setLoading(true);
		if (FirebaseCTX) {
			FirebaseCTX.doSignInWithEmailAndPassword(email, password)
				.then(() => {
					setEmail('');
					setPassword('');
					setUserHasAuthenticated(true)
				}).catch((err) => {
					handleError(err);
				})
				.finally(() => {
					setLoading(false);
				})
		}
	}, [ history, email, password, setLoading, setUserHasAuthenticated ]);

	if (claims) {
		history.push(ROUTES.ADMIN_DASHBOARD);
		return (<Redirect to={ROUTES.ADMIN_DASHBOARD} />);
	}

	const isDisabled: boolean = email === '' || password === '';

	return (
		<div className='bx--grid'>
			<div className='bx--row sc--login-form-wrapper'>
				{isLoading && (
					<Loading
						active
						description='Checking user is authenticated...'
						withOverlay
					/>
				)}

				<React.Fragment>
					<div className='bx--col sc--login-marketing'>
						<img src='/images/SimplyClik-Home-Banner-01.svg' alt='' />
					</div>
					<div className='bx--col-md-3'>
						<div className='login-intro'>
							<p>Log in to Simplyclik <strong>Portal</strong></p>
							<p>Enter your username and password to log into Simplyclik Portal.</p>
						</div>
						<Form
							onSubmit={handleLogin}
						>
							<FormGroup
								legendText=''
							>
								<TextInput
									id='sc--login-email'
									name='email'
									value={email}
									invalid={emailError}
									invalidText={emailErrorMessage}
									labelText='Username'
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										setEmail(event.target.value),
											setEmailError(false),
											setEmailErrorMessage('')
									}}
									placeholder='yourname@example.com'
									type='text'
								/>
								<TextInput
									id='sc--login-password'
									name='password'
									value={password}
									invalid={passwordError}
									invalidText={passwordErrorMessage}
									labelText='Password'
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										setPassword(event.target.value),
											setPasswordError(false),
											setPasswordErrorMessage('')
									}}
									type='password'
								/>
								<Link to={ROUTES.FORGOTTEN_PASSWORD}>Forgot your password?</Link>
							</FormGroup>
							<Button
								disabled={isDisabled}
								kind='primary'
								tabIndex={0}
								type='submit'>
								Login to account
								</Button>
						</Form>
						{userHasAuthenticated && !claims && (
							<InlineNotification
								hideCloseButton={true}
								kind='error'
								notificationType='inline'
								role='alert'
								subtitle="Sorry, you don't have permission to access Simplyclik portal."
								title='Uh ohh...'
							/>
						)}
					</div>
				</React.Fragment>
			</div>
		</div>
	)
}

export default withRouter(LoginContainer);
