/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { withFirebase } from 'components/Firebase';
import { AuthContext } from 'components/Auth';
import { Tile } from 'carbon-components-react/lib/components/Tile';
import SkeletonPlaceholder from 'carbon-components-react/lib/components/SkeletonPlaceholder';
import OverflowMenu from '@carbon/ibm-security/lib/components/OverflowMenu'
import OverflowMenuItem from '@carbon/ibm-security/lib/components/OverflowMenuItem'
import { DataChart } from 'components/Chart/DataChart';
import { EMonths, getDaysOfMonth } from 'components/Chart';
import { Link } from 'react-router-dom';
import { EDateRange, getDateRange } from './DashboardUtilities';


interface IDashboardContainerState {
	barDataLabels: string[];
	chartLoading: boolean;
	contractorCount: number;
	customerCount: number;
	datasets: number[];
	requestCount: number;
	requestCountLabel: string;
	monthByNumber: string;
}

class DashboardContainerBase extends React.Component<any, IDashboardContainerState> {
	static contextType = AuthContext;
	subscribers = [] as any;
	customerRef;
	constructor(props: any) {
		super(props);
		this.state = {
			barDataLabels: [],
			chartLoading: true,
			contractorCount: 0,
			customerCount: 0,
			datasets: [],
			requestCount: 0,
			requestCountLabel: EDateRange.OneMonthAgo,
			monthByNumber: (new Date().getMonth() + 1).toString()
		}
	}

	componentDidMount = (): void => {
		document.body.classList.remove('create-flow-open', 'bx--body--with-modal-open');
		this.customerRef = this.context.claims.customerRef;
		this.getServiceLocationCount();
		this.getContractorCount();
		this.getRequests();
		this.getActivityResultsByMonth(EMonths[ parseInt(this.state.monthByNumber) ])
	}

	componentWillUnmount = (): void => {
		//Remove snapshot listeners on component unmount
		this.subscribers.map((subscriber) => (subscriber()));
	}

	getContractorCount = () => {
		const customerLocationRef = this.props.firebase.customerContractors().doc(this.customerRef).collection('contractors');
		const subscriber = customerLocationRef.onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
			this.setState({
				contractorCount: snapshot.size
			});
		});
		this.subscribers.push(subscriber);
	}

	getServiceLocationCount = (): void => {
		const customerLocationsRef = this.props.firebase.customerLocations().where('customer.id', '==', this.customerRef)
		const subscriber = customerLocationsRef.onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
			this.setState({
				customerCount: snapshot.size
			});
		});
		this.subscribers.push(subscriber)
	}

	getRequests = (value: EDateRange = EDateRange.OneMonthAgo): void => {
		const requestRef = this.props.firebase.requests().where('customer.id', '==', this.customerRef);
		this.setState({
			requestCount: 0,
			requestCountLabel: ''
		})

		const subscriber = requestRef.onSnapshot((snapshot) => {
			let size = 0;
			snapshot.forEach((snap) => {
				if (snap.data().requestStartDate.toMillis() >= getDateRange(value)) {
					size++
				}
			});
			this.setState({
				requestCount: size,
				requestCountLabel: value
			});
		});
		this.subscribers.push(subscriber)
	}

	getActivityResultsByMonth = (value: string) => {

		this.setState({
			chartLoading: true
		})

		const date = new Date();
		const month = EMonths[ value ] - 1;
		const firstDayOfMonth = new Date(date.getFullYear(), month, 1);
		const lastDayOFMonth = new Date(date.getFullYear(), month + 1, 0);

		const daysInMonthArray = getDaysOfMonth(EMonths[ value ] as unknown as number);
		const numberOfDaysArray: number[] = [];

		//loop the number of days of the month and push into array
		[ ...Array(daysInMonthArray).keys() ]
			.forEach((day) => {
				numberOfDaysArray.push((++day));
			});

		this.props.firebase
			.requests()
			.where('customer.id', '==', this.customerRef)
			.where('requestStartDate', '>=', firstDayOfMonth)
			.where('requestStartDate', '<=', lastDayOFMonth)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot.size > 0) {
					const dataset = {};
					querySnapshot.forEach((documentSnapshot) => {
						const requestDay = new Date(documentSnapshot.data().requestStartDate.seconds * 1000).getDate();
						numberOfDaysArray.map((day: number) => {
							if (requestDay === day) {
								{ dataset[ requestDay ] = (dataset[ requestDay ] | 0) + 1 }
							} else {
								{ dataset[ day ] = (dataset[ day ] | 0) }
							}
						});
					});
					this.setState({
						barDataLabels: numberOfDaysArray.map(String),
						datasets: Object.values(dataset)
					})
				} else {
					this.setState({
						datasets: []
					})
				}

			}).catch((err) => {
				console.log(err)
			})
			.finally(() => {
				this.setState({
					chartLoading: false,
					monthByNumber: EMonths[ value ]
				})
			})
	}

	render() {
		return (
			<div className='bx--grid' >
				<div className={'sc--main-title'}>
					<h1>At a glance...</h1>
				</div>
				<div className='sc--activity-tile-container'>
					<div className='bx--row'>
						<div className='bx--col'>
							{this.state.customerCount >= 0
								? (
									<Link className={'sc--activity-tile'} to='/customers'>
										<Tile>
											<div className='sc--tile-detail'>
												<div className='sc--tile-title'>Service Locations</div>
												<div className='ssc--request-menu'>Service locations can be represented by personal at a single location or multiple physical locations.</div>
												<div className='sc--title-count'>{this.state.customerCount}</div>
											</div>
										</Tile>
									</Link>
								)
								: <SkeletonPlaceholder className='sc--activity-tile-skeleton my--skeleton__placeholder--large' />
							}
						</div>
						<div className='bx--col'>
							{this.state.contractorCount >= 0
								? (
									<Tile className={'sc--activity-tile'}>
										<div className='sc--tile-detail'>
											<div className='sc--tile-title'>Contractors</div>
											<div className='ssc--request-menu'>The number of contractors available across your business.</div>
											<div className='sc--title-count'>{this.state.contractorCount}</div>
										</div>
									</Tile>
								)
								: <SkeletonPlaceholder className='sc--activity-tile-skeleton my--skeleton__placeholder--large' />
							}
						</div>
						<div className='bx--col'>
							{this.state.requestCount >= 0
								? (
									<Tile className={'sc--activity-tile'}>
										<div className='sc--tile-detail'>
											<div className='sc--tile-title'>
												<div>Requests</div>

												<div className='sc--request-menu'>
													<span>{`${this.state.requestCountLabel} days`}</span>
													<OverflowMenu
														flipped={true}
													>
														{Object.keys(EDateRange).map((item) => {
															return (
																<OverflowMenuItem
																	key={`${item}`}
																	itemText={`${EDateRange[ item ]} days`}
																	onClick={() => this.getRequests(EDateRange[ item ])}
																/>
															)
														})}
													</OverflowMenu>
												</div>
											</div>
											<div className='ssc--request-menu'>The number of requests received over a period of days.</div>
											<div className='sc--title-count'>{this.state.requestCount}</div>
										</div>
									</Tile>
								)
								: <SkeletonPlaceholder className='sc--activity-tile-skeleton my--skeleton__placeholder--large' />
							}
						</div>
					</div>
				</div>
				<div className='bx--row'>
					<div className='bx--col sc--chart-column'>
						{!this.state.chartLoading ? (
							this.state.datasets.length > 0 ? (
								<DataChart {...{
									barData: {
										data: this.state.datasets,
										labels: this.state.barDataLabels
									}
								}} />
							) :
								(
									<React.Fragment>
										<div className='sc--no-data-message'>
											{`No activity for ${EMonths[ this.state.monthByNumber ]}`}
										</div>
									</React.Fragment>
								)
						)
							: <SkeletonPlaceholder className='sc--activity-tile-skeleton my--skeleton__placeholder--large' />}
						<div className='sc--activity-menu'>
							<span>{`${EMonths[ this.state.monthByNumber ]}`}</span>
							<OverflowMenu
								flipped={true}
							>
								{Object.keys(EMonths).map((item) => {
									if (typeof EMonths[ item ] === 'number') return;
									return (
										<OverflowMenuItem
											key={`${item}`}
											itemText={`${EMonths[ item ]}`}
											onClick={() => this.getActivityResultsByMonth(EMonths[ item ])}
										/>
									)
								})}
							</OverflowMenu>
						</div>
					</div>
				</div>
			</div >
		)
	}
}

const DashboardContainer = withFirebase(DashboardContainerBase);

export default DashboardContainer;
