/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { withFirebase } from 'components/Firebase';
import { ICustomerSummary, ICustomerDetail } from './interface';
import { SkeletonText, Breadcrumb, BreadcrumbItem, OverflowMenu, OverflowMenuItem } from '@carbon/ibm-security';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import * as ROUTES from 'utils/routes';
import { EMonths } from 'components/Chart';
import { IDateRange, GetMonthRange } from 'utils/common';
import ActivityCustomersDetailTable, { IActivityCustomersDetailTableProps } from './ActivityCustomersDetailTable';
import { EPageTitles } from 'utils/config';
import { DocumentTitle } from 'components/DocumentTitle';
import { AuthContext } from 'components/Auth';

interface IActivityContainerState {
	loading: boolean;
	customerLocationRequests: ICustomerDetail[];
	customerRequests: ICustomerSummary[];//ICustomerActivityRequests;
	showSummaryTable: boolean;
	summaryParams: {
		customerName?: string;
		customerId?: string;
	};
	title: string;
	monthByNumber: number;
}

export interface IActivityContainerContext extends IActivityContainerState { }
class ActivityContainerBase extends React.Component<any, IActivityContainerState> {
	static contextType = AuthContext;
	customerRef;
	constructor(props) {
		super(props);
		this.state = {
			customerRequests: [] as ICustomerSummary[],
			customerLocationRequests: [] as ICustomerDetail[],
			loading: true,
			monthByNumber: (new Date().getMonth() + 1),
			showSummaryTable: false,
			summaryParams: {},
			title: ''
		}
	}

	componentDidMount() {
		document.body.classList.remove('create-flow-open', 'bx--body--with-modal-open');
		// this.activitySummaryData();
		this.customerRef = this.context.claims.customerRef;
		this.activityDetailData();
	}

	groupBy = (array: any[], key: string, nested: string = '') => {
		return array.reduce((result, currentValue) => {
			if (nested) {
				(result[ currentValue[ nested ][ key ] ] = result[ currentValue[ nested ][ key ] ] || []).push(currentValue);
			} else {
				(result[ currentValue[ key ] ] = result[ currentValue[ key ] ] || []).push(currentValue);
			}
			return result;
		}, {});
	};

	setMonthRange = (value: number): void => {
		console.log('value', value)
		this.setState({
			monthByNumber: value
		}, () => {
			this.activityDetailData();
		});

	}

	activityDetailData = () => {
		console.log('fetching activity customer details');
		this.setState({
			loading: true
		});
		const customerId = this.customerRef;
		console.log("customerId: " + customerId);
		const fetchCustomerDetailData = this.props.firebase.functions.httpsCallable('fetchCustomerDetailData');
		const month = EMonths[ EMonths[ this.state.monthByNumber ] ] - 1;
		const dateRange: IDateRange = GetMonthRange(month);

		fetchCustomerDetailData({ ...dateRange, customerId })
			.then((response) => {
				console.log(response);
				this.setState({
					customerLocationRequests: response.data.summary,
					showSummaryTable: false,
					loading: false,
					title: "" as string
				})
			})
			.catch((error) => {
				console.log(error)
			})
	};

	handleBreadcrumb = (event: any) => {
		this.setState({
			customerLocationRequests: [],
			showSummaryTable: true,
			title: ''
		})
	};

	render() {
		const activityCustomersDetailTableProps: IActivityCustomersDetailTableProps = {
			rows: this.state.customerLocationRequests,
			loading: this.state.loading
		}

		return (
			<React.Fragment>
				<DocumentTitle title={EPageTitles.Activity} />
				<div className={`sc--activity-wrapper bx--grid`}>
					<div className={'sc--main-title'}>
						{this.state.loading ? (
							<SkeletonText
								heading
								width={'350px'}
							/>
						) : (
							<React.Fragment>
								{this.state.title ? (
									<React.Fragment>
										<Breadcrumb
											// onClick={this.handleBreadcrumb}
											noTrailingSlash
										>
											<BreadcrumbItem>
												<Link
													onClick={this.handleBreadcrumb}
													to={`${ROUTES.ADMIN_ACTIVITY}`}>
													Activity
												</Link>
											</BreadcrumbItem>
											<BreadcrumbItem
												aria-current='page'
												isCurrentPage
												className='sc--current-page'
											>
												<Link
													to={{ pathname: `${ROUTES.ADMIN_ACTIVITY}/${this.state.title}` }}>
													{this.state.title}
												</Link>
											</BreadcrumbItem>
										</Breadcrumb>
										<h1>{`Activity for ${this.state.title}`}</h1>
									</React.Fragment>

								) : <h1>Activity</h1>}
							</React.Fragment>
						)}
					</div>
					<div className='sc--activity-menu'>
						<span>{`${EMonths[ this.state.monthByNumber ]}`}</span>
						<OverflowMenu
							flipped={true}
						>
							{Object.keys(EMonths).map((item) => {
								if (typeof EMonths[ item ] === 'number') return;
								return (
									<OverflowMenuItem
										key={`${item}`}
										itemText={`${EMonths[ item ]}`}
										onClick={() => this.setMonthRange(EMonths[ EMonths[ item ] ] as any as number)}
									/>
								)
							})}
						</OverflowMenu>
					</div>
					<ActivityCustomersDetailTable {...activityCustomersDetailTableProps} />
				</div>
			</React.Fragment>
		)
	}
}

const ActivityContainer = withFirebase(ActivityContainerBase);

export default withRouter(ActivityContainer);
