/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-useless-escape */
import { TextInput, Modal, Button, RadioButton, RadioButtonGroup, UnorderedList, ListItem, InlineNotification } from '@carbon/ibm-security';
import React from 'react';
import ManageContext from './ManageContext';
import { Loader } from 'components/Loader';
import { CustomTextInput, IInputTextFormatProps } from 'components/CustomTextInput';
import { IError, EMode, ICustomerUserDetails, ERoles } from './ManageInterfaces';
import isEqual from 'lodash/isEqual';
import { RegexFormat } from 'utils';
import { AuthContext } from 'components/Auth';


const stepPrefix = 'sc--details';
const classPrefix = 'sc--manage-users';
const scPrefix = 'sc';
const radioRolesMap = [ 'Operator', 'User' ];

interface IAddEditUserProps {
	createNewUser: () => any;
	updateUser: () => any;
}

const AddEditUser: React.FunctionComponent<IAddEditUserProps> = (props: IAddEditUserProps) => {
	const Context = React.useContext(ManageContext);
	const AuthCtx = React.useContext(AuthContext);
	const [ isDisabled, setIsDisabled ] = React.useState<boolean>(true);
	const [ notification, setNotification ] = React.useState<IError>({ message: '', isActive: false });
	const [ currentUser, setCurrentUser ] = React.useState<ICustomerUserDetails>({} as ICustomerUserDetails);
	const [ isModalOpen, setIsModalOpen ] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (Context) {
			setCurrentUser(Context.state.manageCustomerUser)
		}
		document.body.classList.add('sc--manage-users-open');
		return () => {
			document.body.classList.remove('sc--manage-users-open')
		}
	}, []);


	const formatRadioLabel = (action: string): JSX.Element => {
		return (
			<div className={`${classPrefix}-radio-label-text`}>
				<span className={`${classPrefix}-radio-label-text-title`}>{action}</span>
				<span>{`A ${action.toLowerCase()} can perform the following actions:`}</span>
				<UnorderedList
					className={'unordered-list'}
					nested={false}>
					<ListItem>
						approve requests
						</ListItem>
					{action === 'Operator' && (
						<ListItem>
							add and edit customers
						</ListItem>
					)}
				</UnorderedList>
			</div>
		)
	}

	const handleInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {

		Context.dispatch({
			type: 'editableCustomerUser',
			value: { [ event.target.name ]: event.target.value }
		})
	}, [ Context ]);


	const handleRadioButtonChange = React.useCallback((radioSelectedValue: string) => {
		Context.dispatch({
			type: 'editableCustomerUser',
			value: { role: radioSelectedValue }

		})
	}, [ Context ]);


	React.useEffect(() => {
		// if(Context.state.mode === EMode.ADD){
		if (
			Context.state.manageCustomerUser.name === '' ||
			!Context.state.manageCustomerUser.email.match(RegexFormat.email) ||
			Context.state.manageCustomerUser.phone === '' ||
			Context.state.manageCustomerUser.role === '' ||
			isEqual(currentUser, Context.state.manageCustomerUser)
		) {
			setIsDisabled(true)
		} else {
			setIsDisabled(false)
		}
		// } else if (Context.state.mode === EMode.EDIT) {
		// 	if(!Context.state.manageCustomerUser.email){

		// 	}
		// 	setIsDisabled(isEqual(currentUser, Context.state.manageCustomerUser))
		// }

	}, [ setIsDisabled, Context, currentUser ])

	const closeFlowView = React.useCallback(() => {
		Context.dispatch({
			type: 'closeCustomerUserFlow'
		});
	}, [ Context ]);


	const createUser = React.useCallback(() => {
		props.createNewUser()
			.then(() => {
				closeFlowView();
			})
			.catch((errorMessage: string) => {
				setNotification({ message: errorMessage, isActive: true });
			});

	}, [ setNotification, Context ]);

	const updateUser = React.useCallback(() => {
		props.updateUser()
			.then(() => {
				closeFlowView();
			})
			.catch((errorMessage: string) => {
				setNotification({ message: errorMessage, isActive: true });
			});

	}, [ setNotification, Context ]);

	const handleSave = React.useCallback(() => {
		const { mode } = Context.state;
		Context.dispatch({
			type: 'loader',
			value: {
				isLoading: true,
				message: mode === EMode.ADD ? 'Creating new user...' : 'Updating user...'
			}
		});

		mode === EMode.ADD ? createUser() : updateUser();

	}, [ Context ])



	const handleCreateFlowView = React.useCallback((): void => {
		if (!isEqual(currentUser, Context.state.manageCustomerUser)) {
			setIsModalOpen(true);
		} else {
			closeFlowView();
		}
	}, [ setIsModalOpen, closeFlowView, currentUser ]);


	const notificationProps = {
		notificationType: 'inline',
		onCloseButtonClick: () => setNotification({ message: '', isActive: false }),
		className: `${classPrefix}-notification`,
		kind: 'warning',
		title: notification.message
	}

	const renderDangerModal = (): JSX.Element => {
		return (
			<Modal
				className={`${scPrefix}--modal-danger`}
				danger={true}
				open={true}
				iconDescription={'Close the modal'}
				primaryButtonText={'Close without saving'}
				secondaryButtonText={'Continue editing'}
				modalHeading={'You have unsaved changes.'}
				shouldSubmitOnEnter={true}
				onRequestClose={() => setIsModalOpen(false)}
				onRequestSubmit={closeFlowView}
				onSecondarySubmit={() => setIsModalOpen(false)}
			>
				<div>
					<p className='sc--composed-modal--body'>{'If you\'d like to keep editing press the Continue editing button.'}</p>
				</div>
			</Modal>
		)
	}

	const contactPhoneNumberProps: IInputTextFormatProps = {
		disabled: AuthCtx.claims.role === ERoles.USER,
		placeholder: '1234 123 123',
		labelText: 'Phone number',
		format: '#### ### ###',
		name: 'phone',
		required: true,
		value: Context.state.manageCustomerUser.phone as string,
		onChange: handleInput
	}


	return (
		<React.Fragment>
			{isModalOpen && (renderDangerModal())}
			<div className={`${classPrefix} bx--grid`}>
				<div className={`${classPrefix}-intro`}>
					{Context.isLoading && (<Loader message={Context.state.mode === EMode.EDIT ? 'Upadting user details...' : 'Creating a new user...'} />)}
					<div>
						<h2>{Context.state.mode} user</h2>
					</div>
					<div className={`${classPrefix}-button-wrapper`}>
						<Button
							kind='secondary'
							onClick={handleCreateFlowView}
							disabled={false}>
							Cancel
							</Button>
						<Button
							kind='primary'
							onClick={handleSave}
							disabled={isDisabled || AuthCtx.claims.role === ERoles.USER}
						>
							Save
							</Button>
					</div>
				</div>
				<div className={`${classPrefix}-details`}>
					<div className={`${classPrefix}-column`}>
						<div className={`${stepPrefix}-title title`}>User information</div>
						<TextInput
							{...{
								className: `${classPrefix}-input`,
								disabled: AuthCtx.claims.role === ERoles.USER,
								labelText: 'Name',
								id: 'contact-name',
								name: 'name',
								onChange: handleInput,
								placeholder: 'Full name',
								value: Context.state.manageCustomerUser.name
							}}
						/>

						<CustomTextInput {...contactPhoneNumberProps} />

						<TextInput
							{...{
								className: `${classPrefix}-input`,
								disabled: AuthCtx.claims.role === ERoles.USER,
								labelText: 'Email',
								id: 'contact-email',
								placeholder: 'name@customer.com',
								name: 'email',
								onChange: handleInput,
								value: Context.state.manageCustomerUser.email
							}}
						/>
					</div>
					<div className={`${classPrefix}-column`}>
						<div className={`${stepPrefix}-title title`}>Role</div>
						<RadioButtonGroup
							className={`${classPrefix}-action-radio-buttons`}
							labelPosition='right'
							legend='Group Legend'
							name='radio-button-group'
							onChange={handleRadioButtonChange}
							orientation='vertical'
							valueSelected={Context.state.manageCustomerUser.role}
						>
							{radioRolesMap.map((item, index) => {
								return (
									<RadioButton
										className={`${classPrefix}-action-radio-button`}
										disabled={AuthCtx.claims.role === ERoles.USER}
										id={`${classPrefix}-${index}`}
										labelText={formatRadioLabel(item)}
										value={item}
										key={index}
									/>
								)
							})}
						</RadioButtonGroup>
					</div>
				</div>
				{notification.isActive ?
					<InlineNotification {...notificationProps} />
					: null}
			</div>
		</React.Fragment>
	);
};

export { AddEditUser };
