import * as moment from 'moment';
import { IRequestDate } from '../modules/Requests/RequestInterfaces';


export const ConvertTime = (timeMilli: IRequestDate): string => {

	const date = moment(timeMilli.seconds * 1000).format('LLLL');
	return date;

}

export const GetPastTime = (timeInDays: string): number => {
	const date = moment().subtract(timeInDays, 'days').valueOf();
	return date;
}

