import React from 'react';
import RequestContext from './RequestsContext';
import { IRequest } from './RequestInterfaces';
import { RequestTile } from './RequestTile';

export interface IRequestTilesProps {
	requestStatus: string[];
}

const RequestTiles: React.FunctionComponent<IRequestTilesProps> = (props: IRequestTilesProps): JSX.Element => {

	const { state } = React.useContext(RequestContext);
	const { requestStatus } = props;
	const [ requests, setRequests ] = React.useState<IRequest[]>([]);

	React.useEffect(() => {
		if (state.customerRequests) {
			const filteredRequests: IRequest[] = state.customerRequests.filter((request: IRequest) => (
				requestStatus.includes(request.status)
			));
			setRequests(filteredRequests);
		}
	}, [ state, setRequests ]);

	return (
		<React.Fragment>
			{requests.length ? (
				<div className='bx--row'>
					{requests.map((filteredRequest, index) => (
						<div className='bx--col-lg-5 bx--col-md-5 bx--col-sm-8 bx--col-xlg-4 sc--request-tile' key={`${filteredRequest.customer}-${index}`}>
							<RequestTile {...{ customerRequest: filteredRequest }} />
						</div>
					))}
				</div>
			) : (
				<div className='bx--col--auto sc--no-request-notifcation'>
					<h2>{`${state.requestType} requests`}</h2>
					<p>{`It looks like there are no ${state.requestType.toLowerCase()} requests to review.`}</p>
				</div>
			)}
		</React.Fragment>
	)

}

export { RequestTiles };