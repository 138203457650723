/* eslint-disable no-useless-escape */
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Checkbox, TextArea, RadioButtonGroup, RadioButton, TextInput } from '@carbon/ibm-security';
import RequestsContext from '../RequestsContext';
import { ICustomerLocationContractor, EStatus, IRequestDate, ERequestRadioLabels, EType, IRequest } from '../RequestInterfaces';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Draggable24 from '@carbon/icons-react/es/draggable/24';
import { contractorPriorityOrder, requestStatusMap } from '../RequestUtilities';
import { ConvertTime } from 'utils/time';
import { FormatMoney } from 'utils/common';
import { kebabCase } from 'lodash';

const classPrefix = 'sc--request-flow';
const stepPrefix = 'sc--manage';
export interface IManageProps {
	comment: string;
	defaultRadioValue: string;
	handleRadioButtonState: (radioSelectedValue: string) => void;
	handleComment: (event: React.ChangeEvent<HTMLInputElement>) => void;
	selectedValue: string;
	originalStatus: string;
	initialRequestState: IRequest;
}

const Reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [ removed ] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PendingQuoteApprovalCheckbox: React.FunctionComponent = (): any => {
	const { state, dispatch } = React.useContext(RequestsContext);
	const childEl: HTMLElement = document.getElementById('sc--request-flow-awaiting-acceptance') as HTMLElement;
	const parentEl: HTMLElement = childEl.parentElement as HTMLElement;

	const checkBoxHandler = React.useCallback((checked: boolean) => {
		let status: EStatus = EStatus.AWAITING_QUOTE;
		if (!checked) {
			status = EStatus.AWAITING_ACCEPTANCE;
		}
		dispatch({
			type: 'editCustomerRequest',
			value: { status }
		})
	}, [ dispatch ])

	// const purchaseOrderHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { value } = event.target;
	// 	dispatch({
	// 		type: 'editCustomerRequest',
	// 		value: { purchaseOrder: value }
	// 	});
	// }, [ dispatch ]);

	const component = (
		<div className='sc--additional-request-fields bx--col-md-5'>
			{/* <TextInput
				labelText={'Purchase order'}
				id={`${classPrefix}-purchase-order`}
				name={'contractor-contact'}
				onChange={purchaseOrderHandler}
				// disabled: true, //Should only be disabled once contractor has been assigned and has approved to do the works.
				value={state.customerRequest.purchaseOrder}
			/> */}
			<fieldset className={`bx--fieldset sc--${kebabCase(EStatus.AWAITING_QUOTE)}`}>
				<legend className='bx--label'>
					Request quote
				</legend>
				<Checkbox
					id={`sc--${kebabCase(EStatus.AWAITING_QUOTE)}-checkbox`}
					defaultChecked={state.customerRequest.status === EStatus.AWAITING_QUOTE}
					indeterminate={false}
					labelText={'This will inform the assigned contractor that they must provide a quote for the maitnenance request.'}
					onChange={checkBoxHandler}
				/>
			</fieldset>
		</div>
	)
	return ReactDOM.createPortal(component, parentEl as HTMLElement)
}

const ManageStep: React.FunctionComponent<IManageProps> = (props: IManageProps) => {
	const Context = React.useContext(RequestsContext);
	const [ radioValue, setRadioValue ] = React.useState<string>(props.selectedValue || '');
	const [ textAreaInvalid, setTextAreaInvalid ] = React.useState<boolean>(false);
	const [ textAreaDisabled, setTextAreaDisabled ] = React.useState<boolean>(false);
	const [ mounted, setMounted ] = React.useState<boolean>(false);

	const formatRadioLabel = (action): JSX.Element => {
		return (
			<div className={`${classPrefix}-radio-label-text`}>
				<span className={`${classPrefix}-radio-label-text-title`}>{action}</span>
				{requestStatusMap.get(action).desc}
			</div>
		)
	}

	React.useEffect(() => {
		const { status } = Context.state.customerRequest;
		if (status === EStatus.CANCELLED || status === EStatus.COMPLETED || status === EStatus.AWAITING_QUOTE || status === EStatus.AWAITING_ACCEPTANCE) {
			setTextAreaDisabled(true)
		}
		setMounted(true)

	}, []);

	React.useEffect(() => {
		const { customerRequest } = Context.state;
		if ((customerRequest.status === EStatus.CANCELLED || customerRequest.status === EStatus.DECLINED) && !customerRequest.requestComment) {
			setTextAreaInvalid(true);
		} else {
			setTextAreaInvalid(false);
		}

	}, [ Context, setTextAreaInvalid ]);

	const handleRadioButtonChange = React.useCallback((radioSelectedValue: string) => {
		if (radioSelectedValue === EStatus.AWAITING_ACCEPTANCE) {
			setRadioValue(radioSelectedValue);
		} else {
			setRadioValue('')
		}

		props.handleRadioButtonState(radioSelectedValue);

	}, [ setRadioValue, setTextAreaInvalid ]);

	const purchaseOrderHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		Context.dispatch({
			type: 'editCustomerRequest',
			value: { purchaseOrder: value }
		});
	}, [ Context ]);

	const handleOnDragEnd = React.useCallback((result) => {
		const { destination, source } = result;
		if (!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}

		const contractorReorder = Reorder(
			Context.state.requestContractors,
			source.index,
			destination.index
		) as ICustomerLocationContractor[];

		Context.dispatch({
			type: 'requestContractors',
			value: contractorReorder
		});

		Context.dispatch({
			type: 'contractorPriority',
			value: contractorPriorityOrder(contractorReorder)
		})

	}, [ Context ]);

	const renderDNDContractorContext = React.useCallback((): JSX.Element | undefined => {
		// if(Context.state.requestType === ESwitch.PENDING){
		if (Context.state.requestContractors.length) {
			return (
				<React.Fragment>
					<div className={`${stepPrefix}-title title`}>Available Contractors</div>
					{Context.state.requestContractors.length > 1 && (
						<div className={`${stepPrefix}-sub-title`}>Order contractor preference for this request.</div>
					)}
					<DragDropContext
						className={'dnd-contractor-context'}
						onDragEnd={handleOnDragEnd}
					>
						<Droppable droppableId='droppable-1' type='PERSON'>
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									style={{
										backgroundColor: snapshot.isDraggingOver ? '#EFEFEF' : '#F9FAFA',
										padding: '20px'
									}}
									{...provided.droppableProps}
								>
									{Context.state.requestContractors.map((contractor: ICustomerLocationContractor, index: number) => (
										<Draggable draggableId={contractor.contractorId} index={index} key={contractor.contractorId}>
											{(provided) => (
												<div
													className='dnd-contractor-context-list'
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}

												>
													<div className='contractor-card'>
														<div className='contractor-details'>
															<span className={`${classPrefix}-title`}>{contractor.contractorCompanyName}</span>
															<span className={`${classPrefix}-subtitle`}>{contractor.contractorContactName}</span>
															<span className={`${classPrefix}-subtitle--services`}>{[ ...contractor.contractorServices ].join(',  ')}</span>
														</div>
														<Draggable24 />
													</div>
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>

					</DragDropContext>
				</React.Fragment>
			)
		}

		return (
			<React.Fragment>
				<div className={`${stepPrefix}-title title`}>No available contractors for your request.</div>
			</React.Fragment>
		)

	}, [ Context ]);

	const filterRadioButtons = React.useCallback((): string[] => {
		let type: EType = EType.Manager;
		// eslint-disable-next-line no-prototype-builtins
		if (Context.state.customerRequest.hasOwnProperty('contractor')) {
			type = EType.Contractor;
		}
		const filteredArray = Object.values(ERequestRadioLabels).filter((label) => {
			if (requestStatusMap.get(label).filterFor === type) {
				return true
			}
			return false;
		});
		return filteredArray;

	}, [ Context, requestStatusMap ]);

	const shouldBeDisabled = React.useCallback((item?): boolean => {
		const { status } = Context.state.customerRequest;
		if (Context.state.requestContractors.length === 0 && item === ERequestRadioLabels.AssignContractor) {
			return true
		} else if (
			status === EStatus.COMPLETED ||
			props.originalStatus === EStatus.CANCELLED ||
			props.originalStatus === EStatus.AWAITING_ACCEPTANCE ||
			props.originalStatus === EStatus.AWAITING_QUOTE ||
			props.originalStatus === EStatus.ACCEPTED) {
			return true
		}
		return false
	}, [ Context ]);

	const renderUIComponentsForRequestStatus = React.useCallback((): JSX.Element => {
		const { status } = Context.state.customerRequest;
		if (status === EStatus.CONTRACTOR_COMPLETED) {
			return (
				<div>
					The request has been completed by the assigned contractor.
				</div>
			)
		}

		if (status === EStatus.COMPLETED) {
			return (
				<div>
					The request has been completed.
				</div>
			)
		}
		return (
			<React.Fragment>
				{mounted && radioValue === EStatus.AWAITING_ACCEPTANCE && (
					<PendingQuoteApprovalCheckbox />
				)}

				<div className={`${stepPrefix}-title title`}>Action</div>
				<RadioButtonGroup
					defaultSelected={props.originalStatus}
					className={`${classPrefix}-action-radio-buttons`}
					labelPosition='right'
					legend='Group Legend'
					name='radio-button-group'
					onChange={handleRadioButtonChange}
					orientation='vertical'
					valueSelected={props.selectedValue}
				>
					{filterRadioButtons().map((item, index) => {
						return (
							<RadioButton
								className={`${classPrefix}-action-radio-button`}
								id={`${classPrefix}-${kebabCase(requestStatusMap.get(item).status)}`}
								labelText={formatRadioLabel(item)}
								value={requestStatusMap.get(item).status}
								key={index}
								disabled={shouldBeDisabled(item)}
							/>
						)

					})}
				</RadioButtonGroup>

				{(Context.state.customerRequest.status === EStatus.ACCEPTED || Context.state.customerRequest.status === EStatus.PENDING_QUOTE_APPROVAL) && (
					!props.initialRequestState.purchaseOrder && (
						<TextInput
							labelText={'Purchase order (Optional)'}
							helperText={'Your purchase order will be sent to the contractor'}
							id={`${classPrefix}-purchase-order-input`}
							name={'contractor-contact'}
							onChange={purchaseOrderHandler}
							disabled={props.initialRequestState.purchaseOrder}
						/>
					)
				)}
				{Context.state.customerRequest.status !== EStatus.ACCEPTED && (
					< TextArea
						labelText={'Comment'}
						placeholder={'Type here'}
						invalid={textAreaInvalid}
						invalidText={`Please provide a comment as to why the request is being ${Context.state.customerRequest.status.toLowerCase()}.`}
						onChange={props.handleComment}
						disabled={textAreaDisabled}
					/>
				)}
			</React.Fragment>
		)
	}, [ Context, textAreaDisabled ])

	return (
		<div className={`${classPrefix}-step manage`}>

			<div className={`${classPrefix}-column`}>
				{renderUIComponentsForRequestStatus()}
			</div>


			<div className='sc--request-flow-column'>
				{Context.state.customerRequest.contractor ? (
					<React.Fragment>
						<div className={`${stepPrefix}-title title`}>Contractor information</div>
						<TextInput
							{...{
								labelText: 'Name',
								id: `${classPrefix}-contractor-name`,
								name: 'contractor-name',
								disabled: true,
								value: Context.state.customerRequest.contractor.companyName
							}}
						/>
						<TextInput
							{...{
								labelText: 'Contact',
								id: `${classPrefix}-contractor-contact`,
								name: 'contractor-contact',
								disabled: true,
								value: Context.state.customerRequest.contractor.contactName
							}}
						/>
						<TextInput
							{...{
								labelText: 'Phone',
								id: `${classPrefix}-contractor-phone`,
								name: 'contractor-phone',
								disabled: true,
								value: Context.state.customerRequest.contractor.contactPhoneNumber
							}}
						/>

						{Context.state.customerRequest.requestStartDate && (
							<TextInput
								{...{
									labelText: 'Request created',
									id: `${classPrefix}-request-submitted-date`,
									name: 'request-submitted-date',
									disabled: true,
									value: ConvertTime(Context.state.customerRequest.requestStartDate as IRequestDate)
								}}
							/>
						)}
						{props.initialRequestState.purchaseOrder && (
							<TextInput
								{...{
									labelText: 'Purchase order',
									id: `${classPrefix}-purchase-order`,
									name: 'purchase-order',
									disabled: true,
									value: props.initialRequestState.purchaseOrder
								}}
							/>
						)}
						{Object.prototype.hasOwnProperty.call(Context.state.customerRequest.contractor, 'quoteAmount') && Context.state.customerRequest.contractor.quoteAmount !== 0 ? (
							<TextInput
								{...{
									labelText: 'Quote amount',
									id: `${classPrefix}-contractor-quote-amount`,
									name: 'contractor-quote-amount',
									disabled: true,
									value: `$${FormatMoney(Context.state.customerRequest.contractor.quoteAmount)}`
								}}
							/>
						) : null}
						{Context.state.requestInvoicing && (
							<React.Fragment>
								<div className={`${stepPrefix}-title title`}>Invoice details</div>
								{Context.state.requestInvoicing.submitDate && (
									<TextInput
										{...{
											labelText: 'Contractor completed request',
											id: `${classPrefix}-contractor-request-completed`,
											name: 'contractor-request-completed',
											disabled: true,
											value: ConvertTime(Context.state.requestInvoicing.submitDate as IRequestDate)
										}}
									/>
								)}
								{Context.state.requestInvoicing.amount && (
									<TextInput
										{...{
											labelText: 'Invoice amount',
											id: `${classPrefix}-contractor-invoice-amount`,
											name: 'contractor-invoice-amount',
											disabled: true,
											value: `$${FormatMoney(Context.state.requestInvoicing.amount)}`
										}}
									/>
								)}
								{Context.state.requestInvoicing.invoiceNumber && (
									<TextInput
										{...{
											labelText: 'Invoice number',
											id: `${classPrefix}-contractor-invoice-number`,
											name: 'contractor-invoice-number',
											disabled: true,
											value: Context.state.requestInvoicing.invoiceNumber as string
										}}
									/>
								)}
								{/* {Context.state.requestInvoicing.purchaseOrder && (
									<TextInput
										{...{
											labelText: 'Purchase order',
											id: `${classPrefix}-contractor-purchase-order`,
											name: 'contractor-purchase-order',
											disabled: true,
											value: Context.state.requestInvoicing.purchaseOrder as string
										}}
									/>
								)} */}
							</React.Fragment>
						)}
					</React.Fragment>

				) : renderDNDContractorContext()}
			</div>

		</div >
	);
};

export { ManageStep };
