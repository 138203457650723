export interface ICell {
	id: string;
	value?: string;
	info: {
		header: string;
	};
}

export interface IRow {
	cells: ICell[];
	id: string;
}

export interface ITableHeaderItem {
	key: string;
	header: string;
}


// Pagination
const PageSizes = {
	pageSizes: [10, 20, 30, 40, 50]
};

const getItemRangeText = (min: number, max: number, total: number): string => {
	let items: string;
	total === 1 ? (items = 'items') : (items = 'items');
	return `${min} - ${max} of ${total} ${items}`;
};

const getPageRangeText = (current: number, total: number): string => {
	let pages: string;
	total === 1 ? (pages = 'page') : (pages = 'pages');
	return `of ${total} ${pages}`;
};

/**
 *
 */


let searchInputTimeout;
const handleTableSearch = (value: string, data: any, column: string): Promise<void> => {
	const TIME_OUT = 1000;

	if(searchInputTimeout){
		clearTimeout(searchInputTimeout);
	}

	const searchByValue = (value: string, dataSet: any, column: string) => {
		const filteredResults = dataSet.filter((datum: any) => {
			const companyName = datum[column].toLowerCase();
			const tempValue = value.toLowerCase().trim();

			if(companyName.indexOf(tempValue) !== -1 && tempValue !== ''){
				return true;
			}
			return false;
		});

		return(filteredResults);

	}

	return new Promise<void>((resolve) => {
		searchInputTimeout = setTimeout(() => {
				resolve(searchByValue(value, data, column));
		}, TIME_OUT);
	});

}



export { handleTableSearch, getItemRangeText, getPageRangeText, PageSizes };
